import axios from "axios";
import store from "@/store/index.js";
const serviceBaseUrl = process.env.VUE_APP_ADTECH_SERVICE_BASE_URL;

export default {
  async call(endpoint, data) {
    const apiCall = await axios.post(
      serviceBaseUrl,
      {
        jsonrpc: "2.0",
        method: endpoint,
        params: data,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + store.state.userData.accessToken,
        },
      }
    );
    return apiCall.data;
  },

  async httpDownload(endpoint, data, filename) {
    const urlNoJsonrpc = serviceBaseUrl.replace("/jsonrpc", "");

    return axios({
      url: `${urlNoJsonrpc}/${endpoint}`,
      method: "POST",
      responseType: "blob",
      data: data,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + store.state.userData.accessToken,
      },
    }).then((apiCall) => {
      const url = window.URL.createObjectURL(new Blob([apiCall.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      return link.click();
    });
  },
};
